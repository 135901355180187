const ComingSoon = () => {
  return (
    <>
      <div className='simpleslide100'>
        <div
          className='simpleslide100-item bg-img1'
          style={{ backgroundImage: "url(images/bg01.jpg)" }}
        ></div>
        <div
          className='simpleslide100-item bg-img1'
          style={{ backgroundImage: "url(images/bg02.jpg)" }}
        ></div>
        <div
          className='simpleslide100-item bg-img1'
          style={{ backgroundImage: "url(images/bg03.jpg)" }}
        ></div>
        <div
          className='simpleslide100-item bg-img1'
          style={{ backgroundImage: "url(images/bg04.jpg)" }}
        ></div>
      </div>
      <div className='flex-col-c-sb size1 overlay1 p-l-75 p-r-75 p-t-20 p-b-40 p-lr-15-sm'>
        <div className='w-full flex-w flex-sb-m'>
          <div className='wrappic1 m-r-30 m-t-10 m-b-10'>
            <a href='/'>
              {/* <img src='images/icons/logo.png' alt='LOGO' /> */}
              <h1
                className='text-white'
                style={{
                  fontFamily: "Playlist Script",
                  fontSize: "3rem",
                }}
              >
                Jasim.
              </h1>
            </a>
          </div>
          {/* <div className='flex-w cd100 p-t-15 p-b-15 p-r-36'>
            <div className='flex-w flex-b m-r-22 m-t-8 m-b-8'>
              <span className='l1-txt1 wsize1 days'>35</span>
              <span className='m1-txt1 p-b-2'>Days</span>
            </div>
            <div className='flex-w flex-b m-r-22 m-t-8 m-b-8'>
              <span className='l1-txt1 wsize1 hours'>17</span>
              <span className='m1-txt1 p-b-2'>Hr</span>
            </div>
            <div className='flex-w flex-b m-r-22 m-t-8 m-b-8'>
              <span className='l1-txt1 wsize1 minutes'>50</span>
              <span className='m1-txt1 p-b-2'>Min</span>
            </div>
            <div className='flex-w flex-b m-r-22 m-t-8 m-b-8'>
              <span className='l1-txt1 wsize1 seconds'>39</span>
              <span className='m1-txt1 p-b-2'>Sec</span>
            </div>
          </div> */}
          <div className='m-t-10 m-b-10'>
            <a
              href='https://www.youtube.com/CodeBand'
              target='_blank'
              rel='noreferrer'
              className='size2 s1-txt1 flex-c-m how-btn1 trans-04'
            >
              <code>
                <i className='fa fa-youtube-play'></i>
                {" YouTube"}
              </code>
            </a>
          </div>
        </div>

        <div className='flex-col-c-m p-l-15 p-r-15 p-t-80 p-b-90'>
          <h3 className='l1-txt2 txt-center p-b-55 respon1'>Coming Soon</h3>
          <div>
            <a
              className='how-btn-play1 flex-c-m'
              href='https://www.youtube.com/CodeBand'
              target='_blank'
              rel='noreferrer'
            >
              <i className='zmdi zmdi-play'></i>
            </a>
          </div>
        </div>
        <div className='flex-sb-m flex-w w-full justify-content-center'>
          <div className='flex-w flex-c-m m-t-10 m-b-10'>
            <a
              href='https://github.com/akjasim'
              target='_blank'
              rel='noreferrer'
              className='size3 flex-c-m how-social trans-04 m-r-3 m-l-3 m-b-3 m-t-3'
            >
              <i className='fa fa-github'></i>
            </a>
            <a
              href='https://in.linkedin.com/in/akjasim'
              target='_blank'
              rel='noreferrer'
              className='size3 flex-c-m how-social trans-04 m-r-3 m-l-3 m-b-3 m-t-3'
            >
              <i className='fa fa-linkedin'></i>
            </a>
            {/* <a
              href='/'
              target='_blank'
              rel='noreferrer'
              className='size3 flex-c-m how-social trans-04 m-r-3 m-l-3 m-b-3 m-t-3'
            >
              <i className='fa fa-youtube-play'></i>
            </a> */}
            <a
              href='https://www.instagram.com/jasim_ak/'
              target='_blank'
              rel='noreferrer'
              className='size3 flex-c-m how-social trans-04 m-r-3 m-l-3 m-b-3 m-t-3'
            >
              <i className='fa fa-instagram'></i>
            </a>
          </div>
          {/* <form className='contact100-form validate-form m-t-10 m-b-10'>
            <div
              className='wrap-input100 validate-input m-lr-auto-lg'
              data-validate='Email is required: ex@abc.xyz'
            >
              <input
                className='s2-txt1 placeholder0 input100 trans-04'
                type='text'
                name='email'
                placeholder='Email Address'
              />
              <button className='flex-c-m ab-t-r size4 s1-txt1 hov1'>
                <i className='zmdi zmdi-long-arrow-right fs-16 cl1 trans-04'></i>
              </button>
            </div>
          </form> */}
        </div>
      </div>
    </>
  );
};

export default ComingSoon;
